<template>
  <Transition name="fade">
    <slot></slot>
  </Transition>
</template>

<script>
export default {
  name: "Fade",
};
</script>
