/* eslint-disable no-unused-vars */
import "../css/app-base.css";
import "../css/app-components.css";
import "../css/app-utilities.css";
/* eslint-enable no-unused-vars */

import "focus-visible";
import VueSocialSharing from "vue-social-sharing";
import PortalVue from "portal-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Fade from "../vue/Core/Transitions/Fade.vue";

const main = async () => {
  // Async load dependencies
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue");

  const { default: axios } = await import(
    /* webpackChunkName: "axios" */ "axios"
  );

  return {
    Vue,
    axios,
  };
};

// Execute async function
main().then((components) => {
  const { Vue, axios } = components;

  // Add a global instance of axios to Vue
  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  Vue.prototype.$axios = axios;

  Vue.use(VueSocialSharing);
  Vue.use(PortalVue);
  Vue.component("fade", Fade);
  Vue.component("date-picker", DatePicker);

  /* eslint-disable no-unused-vars */
  const vm = new Vue({
    el: "#app",
    components: {
      Board: () =>
        import(/* webpackChunkName: "Board" */ "../vue/Board/List.vue"),
      EventsList: () =>
        import(
          /* webpackChunkName: "EventsList" */ "../vue/Events/ListContainer.vue"
        ),
      EventsSlider: () =>
        import(
          /* webpackChunkName: "EventsSlider" */ "../vue/Events/Slider.vue"
        ),
      EventsCollection: () =>
        import(
          /* webpackChunkName: "EventsCollection" */ "../vue/Events/Collection.vue"
        ),
      FlexibleContentAccordion: () =>
        import(
          /* webpackChunkName: "FlexibleContentAccordion" */ "../vue/FlexibleContent/FlexibleContentAccordion.vue"
        ),
      FlexibleContentCarousel: () =>
        import(
          /* webpackChunkName: "FlexibleContentCarousel" */ "../vue/FlexibleContent/FlexibleContentCarousel.vue"
        ),
      FlexibleContentSlider: () =>
        import(
          /* webpackChunkName: "FlexibleContentSlider" */ "../vue/FlexibleContent/FlexibleContentSlider.vue"
        ),
      FlexibleContentContactCardMoreButton: () =>
        import(
          /* webpackChunkName: "FlexibleContentContactCardMoreButton" */ "../vue/FlexibleContent/FlexibleContentContactCardMoreButton.vue"
        ),
      FlexibleContentPowerBiEmbed: () =>
        import(
          /* webpackChunkName: "FlexibleContentPowerBiEmbed" */ "../vue/FlexibleContent/FlexibleContentPowerBiEmbed.vue"
        ),
      FlexibleContentSurveyMonkeyEmbed: () =>
        import(
          /* webpackChunkName: "FlexibleContentSurveyMonkeyEmbed" */ "../vue/FlexibleContent/FlexibleContentSurveyMonkeyEmbed.vue"
        ),
      MainMenu: () =>
        import(
          /* webpackChunkName: "MainMenu" */ "../vue/MainMenu/MainMenu.vue"
        ),
      FlyoutMenu: () =>
        import(
          /* webpackChunkName: "FlyoutMenu" */ "../vue/FlyoutMenu/FlyoutMenu.vue"
        ),
      Grants: () =>
        import(/* webpackChunkName: "Grants" */ "../vue/Grants/List.vue"),
      NavigationSlider: () =>
        import(
          /* webpackChunkName: "NavigationSlider" */ "../vue/NavigationSlider.vue"
        ),
      Initiatives: () =>
        import(
          /* webpackChunkName: "Initiatives" */ "../vue/Initiatives/ListContainer.vue"
        ),
      InitiativesSlider: () =>
        import(
          /* webpackChunkName: "InitiativesSlider" */ "../vue/Initiatives/Slider.vue"
        ),
      InitiativesCollection: () =>
        import(
          /* webpackChunkName: "InitiativesCollection" */ "../vue/Initiatives/Collection.vue"
        ),
      Jobs: () =>
        import(/* webpackChunkName: "Jobs" */ "../vue/Jobs/ListContainer.vue"),
      People: () =>
        import(
          /* webpackChunkName: "People" */ "../vue/People/ListContainer.vue"
        ),
      Instructor: () =>
        import(
          /* webpackChunkName: "Instructor" */ "../vue/Instructors/ListContainer.vue"
        ),
      Resources: () =>
        import(
          /* webpackChunkName: "Resources" */ "../vue/Resources/ListContainer.vue"
        ),
      ResourcesSlider: () =>
        import(
          /* webpackChunkName: "ResourcesSlider" */ "../vue/Resources/Slider.vue"
        ),
      ResourcesCollection: () =>
        import(
          /* webpackChunkName: "ResourcesCollection" */ "../vue/Resources/Collection.vue"
        ),
      Search: () =>
        import(
          /* webpackChunkName: "Search" */ "../vue/Search/ResultsListContainer.vue"
        ),
      Stories: () =>
        import(
          /* webpackChunkName: "Stories" */ "../vue/Stories/ListContainer.vue"
        ),
      StoriesSlider: () =>
        import(
          /* webpackChunkName: "StoriesSlider" */ "../vue/Stories/Slider.vue"
        ),
      StoriesCollection: () =>
        import(
          /* webpackChunkName: "StoriesCollection" */ "../vue/Stories/Collection.vue"
        ),
      ShareButton: () =>
        import(/* webpackChunkName: "ShareButton" */ "../vue/ShareButton.vue"),
      TooltipButton: () =>
        import(
          /* webpackChunkName: "TooltipButton" */ "../vue/TooltipButton.vue"
        ),
      EntryFilter: () =>
        import(
          /* webpackChunkName: "EntryFilter" */ "#vue/Home/EntryFilter.vue"
        ),
      MultiSelectInput: () =>
        import(
          /* webpackChunkName: "MultiSelectFormInput" */ "#vue/Core/Inputs/MultiSelectFormInput.vue"
        ),
      RichTextFormInput: () =>
        import(
          /* webpackChunkName: "RichTextInputFormInput" */ "#vue/Core/Inputs/RichTextFormInput.vue"
        ),
      DateTimePick: () =>
        import(/* webpackChunkName: "DateTimePick" */ "#vue/DateTimePick.vue"),
      DatePick: () =>
        import(/* webpackChunkName: "DatePick" */ "#vue/DatePick.vue"),
      SidebarAccordion: () =>
        import(
          /* webpackChunkName: "SidebarAccordion" */ "#vue/SidebarAccordion.vue"
        ),
    },
    data() {
      return {
        noticeHidden: false,
        flyoutMenuOpen: false,
        searchBarOpen: false,
      };
    },
    mounted() {
      window.app.emit("vue-mounted");
    },
    methods: {
      hideNotice() {
        this.noticeHidden = true;
        document.cookie = "noticeHidden=true; expires=0: path=/";
      },
      toggleSearchBar() {
        if (!this.searchBarOpen) {
          this.searchBarOpen = true;
          this.flyoutMenuOpen = false;
        } else {
          this.searchBarOpen = false;
        }
      },
      toggleFlyoutMenu() {
        if (!this.flyoutMenuOpen) {
          this.flyoutMenuOpen = true;
          this.searchBarOpen = false;
        } else {
          this.flyoutMenuOpen = false;
        }
      },
    },
  });
  /* eslint-enable no-unused-vars */
});
